import React from 'react'
import { Container } from '../../components/UI/Container'
import { Title } from '../../components/UI/Title'
import { RiBankFill, RiMailLine } from 'react-icons/ri'
import { HiCurrencyDollar } from 'react-icons/hi'
import { VscLaw } from 'react-icons/vsc'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { Link } from 'gatsby'


const BlockchainIcon = () => (
  <div tw="w-8"><img src="/images/blockchain.svg" alt="Blockchain Cube Icon" /></div>
)

const features = [
  {
    icon: RiBankFill,
    title: 'Digital Bank',
    description:
      'Commercium Financial will be a full digitized bank offering blend of traditional banking and new to the market digital asset and securities products.',
    linkText: '',
    url: '',
  },
  {
    icon: BlockchainIcon,
    title: 'Regulatory Driven at the Core of everything we do',
    description:
      'Regulatory driven at the outset to ensure full adherence to all regulations, ably supported by the new to the market immutable blockchain technology being introduced.',
    linkText: '',
    url: '',
  },
  {
    icon: HiCurrencyDollar,
    title: 'Custody of Digital & Fiat Assets',
    description:
      'Commercium Bank will be able to custody both fiat and digital assets.',
    linkText: '',
    url: '',
  },
  {
    icon: VscLaw,
    title: 'Our History and the Importance of SPDI Wyoming Bank Charter Law',
    description:
      'Why is the Wyoming SPDI bank charter law the best solution for qualified custody of digital assets and tokenized securities?',
    linkText: 'Learn more',
    url: '/articles/our-history-and-the-importance-of-spdi-wyoming-bank-charter-law',
  },
  {
    icon: RiMailLine,
    title: 'Message from the CEO',
    description:
      'Wyoming Banking Division has led the nation in creating the regulatory framework that established the Special Purpose Depository Institutions...',
    linkText: 'Learn more',
    url: '/ceo-message',
  },
  {
    icon: FaRegQuestionCircle,
    title: 'Frequently Asked Questions',
    description:
      'Explore the FAQ section for answers to common questions about Commercium.',
    linkText: 'Learn more',
    url: '/faqs',
  },
]

export const AboutUs = () => {
  return (
    <Container
      tw="pt-20 width[100vw] min-h-screen flex flex-col justify-center items-center"
      id="aboutUsSection"
    >
      <Title tw="text-center">About us</Title>

      <div tw="mt-10 grid sm:grid-cols-2 gap-10 pb-20">
        {features.map(({ icon: Icon, title, description, linkText, url }) => (
          <div
            key={title}
            tw="flex space-x-4 bg-blueGray-100 shadow-sm p-6 rounded-md"
          >
            <div>
              <Icon tw="text-5xl w-10 text-blue-800" />
            </div>
            <div>
              <h3 tw="font-semibold text-xl">{title}</h3>
              <p tw="text-blueGray-600 mt-2 text-lg">{description}</p>
              <p tw="text-blueGray-600 mt-2 text-lg text-right">
                <Link to={url} tw="hover:text-blue-800">
                  {linkText}
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>

    </Container>
  )
}
