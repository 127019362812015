import React, { Fragment } from 'react'
//import React, { Fragment, useState } from 'react'
import { Title } from '../../components/UI/Title'
import { Container } from '../../components/UI/Container'
import { styled } from 'twin.macro'
import { Subtitle } from '../../components/UI/Subtitle'
//import { IoLogoLinkedin } from 'react-icons/io'
//import { cx } from '@emotion/css'
//import { Transition } from '@headlessui/react'

interface TeamMember {
  name: string
  role: string
  role2: string
  imagePath: string
  //description: string
  //linkedin?: string
}

/* Removed the following from the bottom of each employee
 *
    description: `Lorem ipsum`,
    linkedin: 'https://www.linkedin.com/in/LoremIpsum/',

    description:
      'Lorem ipsum',
    linkedin: 'https://www.linkedin.com/in/LoremIpsum/',
 *
 */

const members: TeamMember[] = [
  {
    name: 'Corey Reason',
    role: 'Chief Executive Officer &',
    role2: 'Chief Compliance & Risk Officer',
    imagePath: '/images/team/corey-reason.jpeg',
  },
  {
    name: 'Trent Hudson',
    role: 'Chief Financial Officer',
    role2: '',
    imagePath: '/images/team/trent-hudson-bw.jpeg',
  },
  {
    name: 'Timothy Marselle',
    role: 'Chief Information Officer',
    role2: '',
    imagePath: '/images/team/timothy-marselle-bw.jpeg',
  },
  {
    name: 'Britney Reddy',
    role: 'Advisory Board Member',
    role2: '',
    imagePath: '/images/team/britney-reddy-bw.jpeg',
  },
  {
    name: 'Neil Parsons',
    role: 'Chief Operations Officer',
    role2: '',
    imagePath: '/images/team/neil-parsons-bw2.jpeg',
  },
  {
    name: 'Douglas Park',
    role: 'Legal Advisor',
    role2: '',
    imagePath: '/images/team/douglas-park-bw.jpeg',
  },
]

/* Removed the following from the bottom of each employee
 *
    description: `Lorem ipsum`,
    linkedin: 'https://www.linkedin.com/in/LoremIpsum/',
 *
 */

const boardMembers: TeamMember[] = [
  {
    name: 'Lou Terhar',
    role: 'Board Chairman',
    role2: '',
    imagePath: '/images/team/lou-terhar.jpeg',
  },
  {
    name: 'John Eklund',
    role: 'Board Secretary',
    role2: '',
    imagePath: '/images/team/john-eklund.png',
  },
  {
    name: 'Robert Slater',
    role: 'Board Audit Chair',
    role2: '',
    imagePath: '/images/team/robert-slater.png',
  },
  {
    name: 'David A. Cain',
    role: 'Board Technology Chair',
    role2: '',
    imagePath: '/images/team/david-cain.jpg',
  },
]

const StyledImage = styled.img`
  filter: grayscale(90%);
`

//interface LinkedinWrapperProps {
//  linkedin: string
//}
//

/*
 * No longer used

const LinkedinWrapper = ({ linkedin }: LinkedinWrapperProps) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <div
      tw="flex lg:hidden absolute w-full top-0 z-10 p-4 text-base bg-blueGray-400 bg-opacity-90 h-full overflow-y-auto transition ease-in duration-300 opacity-0 hover:opacity-100 whitespace-pre-wrap text-left justify-center items-center"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <Transition
        as={Fragment}
        show={isActive}
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <a
          href={linkedin}
          target="_blank"
          rel="noreferrer noopener"
          className={cx({
            'pointer-events-none': !isActive,
          })}
        >
          <IoLogoLinkedin tw="text-5xl" />
        </a>
      </Transition>
    </div>
  )
}
 *
 */

/*
 * This code was removed from both the "Executive Team" section and the "Board of Directors" section.
 * This is located below the "StyledImage" tag.
 * 
 * The code creates a hover over on each image displaying text in browser mode, 
 * and a LinkedIn link and icon in mobile mode.
 *
                <div tw="hidden lg:flex absolute top-0 z-10 p-4 text-base bg-blueGray-400 bg-opacity-90 h-full overflow-y-auto transition ease-in duration-300 opacity-0 hover:opacity-100 whitespace-pre-wrap text-left items-center">
                  {description}
                </div>
                {linkedin && <LinkedinWrapper linkedin={linkedin} />}
 *
 **************************************
 * 
 * Removed "description" and "linkedin" from the below:
 * 
   {members.map(({ name, imagePath, role, description, linkedin }
 * 
 */
export const OurTeam = () => {
  return (
    <>
      <Container tw="py-20" id="ourTeamSection">
        <Title>
          Meet Commercium
        </Title>
        <div tw="mt-3 gap-4 sm:gap-10">
          <p>
            Our Commercium team members have a combined experience of over 
            180 years within the banking industry, specializing in 
            compliance, legal, regulatory, sales, financial services, 
            change management, legislative, and operations.
          </p>
        </div>
        <Subtitle tw="uppercase xl:text-2xl mt-10 text-blue-800">
          Executives
        </Subtitle>
        <div tw="mt-10 grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-10">
          {members.map(({ name, imagePath, role, role2 }) => (
            <div key={name} tw="text-center">
              <div tw="w-full h-80 relative rounded-md overflow-hidden">
                <StyledImage
                  src={imagePath}
                  alt="Member"
                  tw="w-full h-full object-cover shadow-lg"
                />

              </div>
              <div tw="mt-4">
                <h5 tw="font-semibold">{name}</h5>
                <p tw="text-blueGray-500">{role}</p>
                <p tw="text-blueGray-500">{role2}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>

      <Container tw="pb-20">
        <Subtitle tw="uppercase xl:text-2xl mt-10 text-blue-800">
          Board of Directors
        </Subtitle>
        <div tw="mt-10 grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-10">
          {boardMembers.map(
            ({ name, imagePath, role }) => (
              <div key={name} tw="text-center">
                <div tw="w-full h-80 relative rounded-md overflow-hidden">
                  <StyledImage
                    src={imagePath}
                    alt="Member"
                    tw="w-full h-full object-cover shadow-lg"
                  />

                </div>
                <div tw="mt-4">
                  <h5 tw="font-semibold">{name}</h5>
                  <p tw="text-blueGray-500">{role}</p>
                </div>
              </div>
            )
          )}
        </div>
      </Container>
    </>
  )
}
