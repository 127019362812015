import React from 'react'
import { Container } from '../../components/UI/Container'
import { Title } from '../../components/UI/Title'

export const OurCompany = () => {
  return (
    <Container
      tw="pt-20 width[100vw] flex flex-col justify-center items-center"
      id="ourCompanySection"
    >
      
      <div
        style={{
          background: 'url(/images/bg/map-dotted.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        tw="text-center py-20 space-y-10 w-full"
      >
        <div tw="mt-10 grid sm:grid-cols-2 gap-10 pb-20">
          <div>
            <Title tw="xl:text-5xl tracking-normal">Our company</Title>
            <p tw="text-blueGray-600 max-w-xl mt-2 mx-auto text-lg">
              As a chartered bank, Commercium Financial is legally able to offer a variety
              of new services, each of which will help the company achieve its end
              goal of bridging traditional wealth management with the world of
              blockchain technology and Digital Assets.
            </p>
          </div>
          <div>
            <Title tw="xl:text-5xl tracking-normal">Where are we?</Title>
            <p tw="text-blueGray-600 max-w-xl mt-2 mx-auto text-lg">
              Although chartered and headquartered in the State of Wyoming,
              Commercium Bank will be able to operate in most US States and
              territories under a unified regulatory framework through reciprocity
              agreements.
            </p>
          </div>
        </div>
      </div>

    </Container>
  )
}
