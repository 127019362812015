import React from 'react'
import { Container } from '../../components/UI/Container'
import { Title } from '../../components/UI/Title'
import { Link } from 'gatsby'
import { styled } from 'twin.macro'


const articles = [
  {
    title: 'SPDI versus a Trust Company - What is the difference?',
    description:
      'How is a Special Purpose Depository Institution (SPDI) compared/different to a Trust Company (TC)?',
    imagePath: '/images/articles/spdi-trust.jpg',
    linkText: 'Read more',
    url: '/articles/how-is-a-special-purpose-depository-institution-compared-to-a-trust-company',
  },
  {
    title: 'Rule 15c3-3 Qualified Custody of Securities and Its Importance in Secondary Market Trading',
    description:
      'Rule 15c3-3 qualified custody of securities is an essential component of the securities market, playing a crucial role in safeguarding customer assets and promoting investor confidence.',
    imagePath: '/images/articles/custody-secondary-market.jpg',
    linkText: 'Read more',
    url: '/articles/rule-15c3-3-qualified-custody-of-securities-and-its-importance-in-secondary-market-trading',
  },
  {
    title: 'Understanding Controllable Electronic Records (CERs) and the Proposed Uniform Commercial Code Revisions',
    description:
      'While they have been traditionally called "tokens", there\'s a new legal term that\'s a better definition - a "Controllable Electronic Record"',
    imagePath: '/images/articles/understanding-cers.jpg',
    linkText: 'Read more',
    url: '/articles/understanding-controllable-electronic-records-cers-and-the-proposed-uniform-commercial-code-revisions',
  },
]

const StyledImage = styled.img`
`

export const Articles = () => {
  return (
    <Container
      tw="pt-20 width[100vw] flex flex-col justify-center items-center"
      id="articlesSection"
    >
      <Title tw="text-center">Insights</Title>

      <div tw="mt-10 grid md:grid-cols-3 gap-10 pb-20">
        {articles.map(({ title, description, imagePath, linkText, url }) => (
          <div
            key={title}
            tw="flex space-x-4 bg-blueGray-100 shadow-sm p-6 rounded-md"
          >
            <div>
              <Link to={url} tw="hover:opacity-80">
                <StyledImage
                    src={imagePath}
                    alt={title}
                    tw="w-auto h-auto object-cover shadow-lg mb-4"
                  />
                </Link>
              <h3 tw="font-semibold text-xl">{title}</h3>
              <p tw="text-blueGray-600 mt-2 text-base">{description}</p>
              <p tw="text-blueGray-600 mt-2 text-base text-right">
                <Link to={url} tw="hover:text-blue-800">
                  {linkText}
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>

    </Container>
  )
}
