import React, { ReactElement } from 'react'
import { Navbar } from '../components/Layout/Navbar'
import { Container } from '../components/UI/Container'
import { Title } from '../components/UI/Title'
import { OurCompany } from '../views/home/OurCompany'
import { AboutUs } from '../views/home/AboutUs'
import { Articles } from '../views/home/Articles'
import { OurTeam } from '../views/home/OurTeam'
import { Footer } from '../components/Layout/Footer'
/* import { CEOMessage } from '../views/home/CEOMessage' */
import { styled } from 'twin.macro'
import { Helmet } from 'react-helmet'

const StyledBackgroundWrapper = styled.div`
  filter: grayscale(45%);
`

function Index(): ReactElement {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Commercium Financial, Inc.</title>
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="16fb35e4-d2b1-4002-ae25-2fde17dcf1a2"
            data-blockingmode="auto"
            type="text/javascript"
          />
        </Helmet>
        <Navbar />

        <Container tw="min-h-screen flex items-center justify-center text-white z-10">
          <div tw="flex flex-col items-center">
            <Title tw="lg:text-7xl xl:text-9xl z-10 text-center">Commercium Financial</Title>
          </div>

          <StyledBackgroundWrapper tw="absolute z-index[-10] w-full h-screen max-h-screen">
            <img
              src="/images/bg/home.jpeg"
              alt="Background"
              tw="w-full h-full bg-cover"
            />
          </StyledBackgroundWrapper>
        </Container>

        <OurCompany />
        <AboutUs />
        <Articles />
        {/* <CEOMessage /> */}
        <OurTeam />
        {/* <Newsletter /> */}

        <Footer />
      </div>
    </>
  )
}

export default Index
